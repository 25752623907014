.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 0 20px 0;
  .filters {
    display: flex;
    align-items: center;
    div {
      margin-right: 20px;
      display: flex;
      input[type="checkbox"] {
        height: 18px;
        width: 18px;
      }
      input[type="text"] {
        padding: 5px;
      }
    }
  }
}
