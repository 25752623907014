.header {
  .title {
    background: #e50058;
    color: white;
    padding: 10px;
    font-size: 13.5pt;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .type {
      margin-left: 10px;
    }
  }
  .headerItems {
    .bottomItem {
      display: flex;
      justify-content: space-between;
      max-width: fit-content;
      align-items: center;
    }
    .author{
      margin: 5px 0;
    }
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: fit-content;
      font-size: 10pt;
      margin-right: 50px;
      .itemName {
        margin-right: 10px;
        width: 170px;
        &.dates{
          width: 100px;
        }
      }
      .itemValue {
        display: flex;
        align-items: center;
        button {
          margin-left: 10px;
          cursor: pointer;
          margin-right: -53px;
        }
      }
      span {
        font-size: 10pt;
        margin-left: 10px;
        color: blue;
        &.red {
          color: red;
        }
        &.green {
          color: green;
          margin-left: 23px;
        }
        &.date{
          margin-right: 17px;
        }
      }
    }
  }
  .popupWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    .popup {
      width: 200px;
      height: 100px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
}

.formGroup {
  color: #393939;
  display: flex;
  width: 400px;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  &.formGroupBtn {
    width: 200px;
  }
  label {
    margin-right: 20px;
  }
}