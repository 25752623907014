.pdf {
  width: 80%;
  transition: all 0.3s ease;
  height: calc(100vh - 60px);
  overflow: hidden;
  &.full {
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
}
