.wrapper{
  width: 100%;
  padding: 20px;
  .formGroup{
    display: flex;
    width: 600px;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    .year{
      display: flex;
      align-items: center;
      select{margin-right: 20px;}
    }
  }
  .checkboxGroup{
    display: flex;
    justify-content: space-between;
    label{
      margin-right: 20px;

    }
  }
}