.table {
  width: 100%;
  margin-bottom: 20px;
  .rows {
    height: 80vh;
    overflow: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
  .row {
    background-color: lightgray;
    border: 1px solid #000;
    &.title {
      background-color: lightblue;
    }
    .col {
      padding: 5px 10px;
      border-right: 1px solid #000;
      text-align: center;
      vertical-align: middle;
      &.id {
        width: 50px;
        justify-content: center;
      }
      &.other {
        width: 200px;
      }
      &.btnCol {
        width: 120px;
        justify-content: center;
        .btn:not(:hover) {
          background-color: rgba(0, 255, 255, 0.2);
        }
      }
      &.img {
        width: 10%;
        img {
          width: 100%;
        }
      }
      &.name {
        width: 400px;
      }
    }
    &.odd {
      background-color: #fff;
    }
  }
}
