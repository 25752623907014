.editor {
  text-align: right;
  font-size: 10pt;
  color: black;
  min-width: 700px;
  max-width: 700px;
  h1{
    font-size: 32px;
  }
  .saveBtn {
    margin-top: 10px;
  }
}
