.btns{
  width: fit-content;
}
.elementWrapper{
  margin-left: 20px;
  margin-bottom: 20px;
.element{
  display: flex;
  min-width: 300px;
  margin-bottom: 10px;
  .name{
    display: flex;
    align-items: center;
    line-height: 1.2em;
  }
  svg {
    cursor: pointer;
    margin: 0 0 0 8px;
    color: #393939;
    font-size: 20px;
    &:hover {
      color: #2684ff;
    }
  }
  .delete svg {
    color: #c72762e0;
    &:hover {
      color: #2684ff;
    }
  }
}
table,tr,td{
  border: 1px solid black;
}
td{
  padding: 5px;
}
table{
  margin-bottom: 10px;
  margin-left: 20px;
}
.formGroup {
  display: flex;
  width: 400px;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  &.formGroupBtn {
    width: 200px;
  }
  label {
    margin-right: 20px;
  }
}}
.oneBtn{
  justify-content: center !important;
}