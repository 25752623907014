.pdf {
  width: 100%;
  height: calc(100vh - 190px);
  overflow: hidden;
  iframe {
    width: 100%;
    height: 100%;
  }
  .back{
    position: fixed;
    right: 50px;
    top: 230px;
  }
}
