.fileField{
  margin: 5px;
.itemTitle span {
  text-decoration: underline;
}

.itemValue {
  color: blue;
  .itemValue {
    display: flex;
    align-items: center;
  }
}
.popupWrapper {
  color: #393939;
  .fileName {
    font-size: 12px;
    color: blue;
  }
}
.audItem {
  display: flex;

  .audName {
    color: black;
  }
}

.file {
  div {
    width: fit-content;
    text-decoration: underline;
    cursor: pointer;
  }
}

.red {
  color: red;
}

.formGroup {
  color: #393939;
  display: flex;
  width: 400px;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  &.formGroupBtn {
    width: 200px;
  }
  label {
    margin-right: 20px;
  }
}

.fileWrapper {
  display: flex;
  align-items: center;
  margin: 10px;
  svg {
    cursor: pointer;
    margin: 0 0 0 8px;
    color: #393939;
    font-size: 20px;
    &:hover {
      color: #2684ff;
    }
  }
  .delete svg {
    color: #c72762e0;
    &:hover {
      color: #2684ff;
    }
  }
  .changeFile {
    margin-left: 50px;
  }
}
}

.fontRed {
  color: #e50058;
}