.discussion {
  padding: 10px;
  width: 80%;
  transition: all 0.3s ease;
  overflow: auto;
  overflow-x: hidden;
  padding-bottom: 0px;
  &::-webkit-scrollbar {
    width: 0;
  }
  &.full {
    width: 100%;
  }
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    background: #0066d2;
    margin-bottom: 20px;
    .title {
      color: white;
      padding: 10px;
      font-size: 13.5pt;
      display: flex;
      align-items: center;
      .type {
        margin-left: 10px;
      }
    }
    .discussionId {
      text-align: end;
      font-size: 12px;
      color: #fff;
      margin-right: 5px;
    }
  }
  .setEditBtn {
    position: fixed;
    right: 20px;
    right: 130px;
    .btn {
      margin-right: 20px;
    }
    button {
      padding: 10px;
      cursor: pointer;
    }
  }
  .saveInHistoryBtn {
    right: 20px;
  }
  .saveBtn {
    right: 20px;
    padding: 10px;
  }
  .mainData {
    font-size: 13.5pt;
    color: #0066d2;
    margin-bottom: 10px;
  }
  .disscussionMap {
    color: blue;
    cursor: pointer;
  }
  .tabs {
    display: flex;
    .tab {
      padding: 5px;
      border: 1px solid black;
      border-bottom: none;
      border-right: none;
      cursor: pointer;
      &.active {
        color: white;
        background: #666;
      }
      &:last-child {
        border-right: 1px solid black;
      }
    }
  }
  .pannels {
    .pannel {
      overflow: auto;
      overflow-x: hidden;
      height: calc(100vh - 195px);
      position: relative;
      border: 1px solid black;
      padding: 10px;
      display: none;
    }
    .active {
      display: block;
    }
  }
}
.bottomSpace {
  margin-bottom: 200px;
}
@media print {
  .discussion {
    width: 100%;
    .pannels {
      .pannel {
        height: auto;
      }
    }
  }
}
