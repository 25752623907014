.table {
    width: 80vw;
    margin: 30px auto;
    position: relative;
    .tableItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 50px;
      margin-top: 20px;
      cursor: pointer;
      -moz-box-shadow: -1px 7px 11px 2px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: -1px 7px 11px 2px rgba(0, 0, 0, 0.5);
      -ms-box-shadow: -1px 7px 11px 2px rgba(0, 0, 0, 0.5);
      box-shadow: -1px 7px 11px 2px rgba(0, 0, 0, 0.5);
      .name {
        font-size: 24pt;
        font-weight: bold;
        line-height: 1.5em;
        margin-right: 50px;
      }
      .metaData {
        display: flex;
        .date,
        .place {
          font-size: 16pt;
          line-height: 1.5em;
        }
        .date{
          margin-right: 50px;
        }
      }
    }
    .tableItemWrapper{
      position: relative;
    .editMeetingBtn{
      position: absolute;
      left:-105px;
      top:60%;
      transform: translate(0, -50%);
      padding: 5px;
      background-color: #598AD1;
      color: white;
      box-shadow: -1px 3px 11px 1px rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }}
  }
  