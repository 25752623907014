.header {
  background-color: lightblue;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0 2%;
  align-items: center;
  position: relative;
  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      height: 50px;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .welcome {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
    .message {
      margin-right: 20px;
      font-size: 26px;
      margin-top: 3px;
      color: white;
    }
  }
  .adminBtn{
    /*position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%,-50%);
    &:hover{
      top: calc(50% - 1px);
    }*/
    margin-right: 20px;
  }
  button{
    background-color: #fff;
  }
}
