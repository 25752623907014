.textField {
  margin: 5px;
  .itemTitle span {
    text-decoration: underline;
  }
  .itemValue {
    color: blue;
    .itemValue {
      display: flex;
      align-items: center;
    }
  }
  .audItem {
    display: flex;
    .audName {
      color: black;
    }
  }
}
