.tabs {
  display: flex;
  .tab {
    width: fit-content;
    margin-right: 5px;
    &.current {
      color: white;
      background-color: #2684ff;
    }
  }
}
.plates {
  border: 1px solid black;
  margin-top: 20px;
  padding: 20px;
  .btn {
    width: fit-content;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
  .table {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    margin-top: 10px;
    .rows {
      height: 80vh;
      overflow: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .row {
      background-color: lightgray;
      border: 1px solid #000;
      &.title {
        background-color: lightblue;
      }
      .col {
        padding: 10px;
        border-right: 1px solid #000;
        text-align: center;
        vertical-align: middle;
        &.id {
          width: 50px;
          justify-content: center;
        }
        &.other {
          width: 200px;
        }
        &.btnCol {
          justify-content: center;
          min-width: 85px;
          .btn {
            margin-right: auto;
            margin-left: auto;
          }
        }
        &.img {
          width: 10%;
          img {
            width: 100%;
          }
        }
        &.name {
          width: 400px;
        }
      }
      &.odd {
        background-color: #fff;
      }
      .discussion {
        width: 16.5%;
        text-align: justify;
        .oneDiscussion {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 5px;
          border-bottom: 1px solid #000;
          align-items: center;
          .report {
            cursor: pointer;
            color: #2684ff;
          }
          .oneDiscussionIcons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          .oneDiscussionIcon {
            display: flex;
            justify-content: flex-end;
            font-size: 18px;
            width: 50px;
            svg {
              cursor: pointer;
              color: #c72762e0;
              margin-right: 5px;
              &:first-child {
                color: #393939;
                font-size: 20px;
              }
              &:hover {
                color: #2684ff;
              }
            }
          }
          .arrows {
            display: flex;
            flex-direction: column;
            margin-left: 5px;
            align-items: center;
            justify-content: center;
            margin-bottom: 5px;
            .arrow {
              cursor: pointer;
              transition: all 0.25s ease;
              &:hover {
                color: #2684ff;
              }
            }
            .upArrow {
              margin-bottom: 5px;
            }
          }
          .oneDiscussionName {
            font-size: 14px;
            line-height: 1.2em;
            margin-right: 20px;
            display: flex;
            span {
              padding-left: 5px;
            }
            .numberSpan {
              padding: 0;
            }
          }
        }
      }
      .info {
        text-align: justify;

        .number {
          display: flex;
          align-items: center;
          justify-content: center;
          vertical-align: middle;
          span {
            margin-right: 10px;
          }
          div {
            display: flex;
            flex-direction: column;
            svg {
              cursor: pointer;
            }
            svg:first-child {
              margin-bottom: 5px;
            }
          }
        }
        .visible{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          font-size: 24px;
        }
      }
    }
  }
}
.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  .popup {
    width: fit-content;
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .text {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .btn {
      width: fit-content;
    }
    .red {
      color: red;
    }
  }
}
.formGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  &.btns {
    margin-bottom: 0;
  }
}
