.sidebar {
  width: 10%;
  padding: 100px 0 0 30px;
  overflow: auto;
  overflow-x: hidden;
  height: calc(100vh - 60px);
  background-color: #cccccc;
  color: #0066d2;
  font-size: 20px;
  &::-webkit-scrollbar {
    width: 0;
  }
  div {
    margin-bottom: 10px;
    svg {
      margin-right: 10px;
    }
  }
  .df {
    display: flex;
    align-items: center;
  }
}
