.disscussionMap {
  color: blue;
  cursor: pointer;
  font-size: 9pt;
  margin: 10px 0;

}

@media print {
  .disscussionMap {
   display: none;
  }
}