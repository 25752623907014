.header {
    text-align: center;
    width: 100vw;
    padding-top: 20px;
    .year {
      font-weight: bold;
      font-size: 24.0pt;
      margin: 20px 0 30px;
      span{
          font-style: italic;
      }
    }
    .title{
      font-weight: bold;
      font-size: 36.0pt;
      margin-bottom: 30px;
    }
    .lom{
      font-size: 32px;
      line-height: 1.5em;
    }
    .addMeetingBtn{
      position: absolute;
      top:5px;
      right:5px;
      padding: 5px;
      background-color: #598AD1;
      color: white;
      box-shadow: -1px 3px 11px 1px rgba(0, 0, 0, 0.5);
      cursor: pointer;
    }
  }
  