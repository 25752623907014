.sidebar {
  position: relative;
  width: 0%;
  background-color: #cccccc;
  transition: all 0.3s ease;
  padding-right: 20px;
  &::-webkit-scrollbar {
    width: 0;
  }
  &.show {
    width: 20%;
    padding-right: 20px;
  }
  .sidebarWrapper {
    transition: all 0.3s ease;
    opacity: 0;
    overflow-x: hidden;
    overflow: auto;
    height: calc(100vh - 60px);
    &.show {
      opacity: 1;
    }
  }
  .topBtns {
    display: flex;
    justify-content: space-between;
    width: 60%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .top {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-top: 30px;
    .mainTitle {
      line-height: 1.5em;
      font-size: 16px;
      margin-left: 20px;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .content {
      margin-left: 20px;
      margin-bottom: 20px;
    }
    img {
      width: 60%;
      margin-bottom: 20px;
      align-self: center;
    }
  }
  .link {
    margin-top: 5px;
    cursor: pointer;
    font-size: 12pt;
    color: blue;
    &:hover {
      opacity: 0.5;
    }
  }
  .bottom {
    margin-top: 20px;
    &Item {
      .title {
        background-color: #8e9092;
        color: white;
        text-align: center;
        padding: 2px;
        margin-bottom: 5px;
        position: relative;
        button {
          position: absolute;
          right: 0;
          margin-right: 5px;
          top: 3px;
        }
      }
      table {
        margin: 0 auto;
        margin-bottom: 20px;
        width: 100%;
        td {
          padding: 5px;
          text-align: center;
          vertical-align: middle;
        }
      }
      table,
      tr,
      td {
        border: 1px solid black;
      }
    }
  }
  .resizer {
    position: absolute;
    top: 50%;
    right: -4px;
    z-index: 1;
    color: black;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
}
@media print {
  .sidebar {
    display: none;
  }
}
