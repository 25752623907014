.wrapper {
  width: 100%;
  padding: 20px;
  height: calc(100vh - 80px);
  .formGroups {
    display: flex;
    .mainInfo {
      margin-right: 50px;
    }
    .title {
      text-align: center;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .content {
      width: 100%;
      .btns {
        width: fit-content;
      }
      &.sections {
        height: 80vh;
        overflow: auto;
        overflow-x: hidden;
      }
      .sectionWrapper {
        display: flex;
        padding: 10px;
        border: 1px solid #add8e6;
        margin-bottom: 10px;
      }
    }
  }
  .formGroup {
    display: flex;
    width: 400px;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    select{
      max-width: 200px;
    }
    &__template {
      width: 100%;
    }
    &__templateSelect {
      justify-content: flex-start;
      height: 40px;
      margin-bottom: 0;
      select {
        margin-right: 20px;
      }
    }
    &.formGroupBtn {
      width: 200px;
    }
    .addChecker {
      cursor: pointer;
      font-size: 20px;
      padding: 2px 5px 0px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      width: fit-content;
      margin: 0 0 0;
      transition: all 250ms;
      &:hover {
        color: white;
        background: #2684ffdd;
      }
    }
    .file {
      display: flex;
      align-items: center;
      svg {
        font-size: 20px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
    .checkersWrapper {
      border: 1px solid #add8e6;
      padding: 5px;
      .checkers {
        .checker {
          min-width: 250px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .name {
            margin-right: 20px;
          }
        }
        span {
          margin-left: 20px;
          margin-right: 0;
        }
        div {
          margin-bottom: 5px;
        }
      }
    }
    .checkersSelect {
      display: flex;
      width: 100%;
      justify-content: space-between;
      span {
        margin-left: 20px;
        margin-right: 0;
      }
    }
  }
  .checkboxGroup {
    display: flex;
    justify-content: space-between;
    label {
      margin-right: 20px;
    }
  }
}
.delete {
  color: #c72762e0;
  cursor: pointer;
  padding: 2px;
  transition: all 250ms;
  &:hover {
    color: #2684ffdd;
  }
}
.anotherBtn {
  margin: 0 auto;
}
.message {
  color: red;
}
.mr20 {
  margin-right: 20px;
}
.oneBtn{
  justify-content: center !important;
}
.meetingInfo{
  font-size: 18px;
  margin-bottom: 20px;
  line-height: 1.5em;
  width: fit-content;
  border-bottom: 1px solid #000;
  .title{
    font-size: 20px;
  }
}