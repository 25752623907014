.section {
  table {
    min-width: 600px;
    .delete {
      display: block;
      margin: 5px auto;
    }
    tr {
      td {
        min-width: 50px;
      }
      .tableHeaders {
        text-align: center;
        color: black;
      }
    }
  }
  .topLine {
    width: 100%;
    height: 3px;
    background: #0066d2;
    margin-bottom: 20px;
  }
  .title {
    font-size: 13.5pt;
    color: #0066d2;
    margin-bottom: 10px;
  }
  .item {
    font-size: 10pt;
    padding-bottom: 20px;
    border-bottom: 1px solid #0066d2;
    &:last-child {
      border-bottom: none;
    }
    .itemTitle span {
      border-bottom: 1px solid #000;
    }
    .itemValue {
      color: blue;
      .itemValue {
        display: flex;
        align-items: center;
      }
      .itemFiles {
        div {
          margin-left: 20px;
        }
      }
    }
    .audItem {
      display: flex;
      .audName {
        color: black;
      }
    }
  }
}
@media print {
  .section {
    div,
    p {
      page-break-inside: avoid;
    }
  }
}
