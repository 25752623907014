.wrapper {
  width: 100%;
  padding: 20px;
  .formGroup {
    display: flex;
    width: 400px;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    .file {
      display: flex;
      align-items: center;
      svg {
        font-size: 20px;
        margin-left: 20px;
        cursor: pointer;
      }
    }
  }
  .checkboxGroup {
    display: flex;
    justify-content: space-between;
    label {
      margin-right: 20px;
    }
  }
}
.meetingFile {
  cursor: pointer;
}
.delete {
  cursor: pointer;
  color: #c72762e0;
  &:first-child {
    margin-right: 8px;
    color: #393939;
    font-size: 20px;
  }
  &:hover {
    color: #2684ff;
  }
}
.input{
  width: 75%;
}