.program {
  width: 700px;
  margin: 0 auto;
  font-size: 13.5pt;
  .programItem {
    &Title {
      display: flex;
      span {
        margin-right: 5px;
        display: block;
        text-align: right;
        min-width: 20pt;
        margin-bottom: 20px;
      }
    }
    &Content {
      .link {
        margin-top: 5px;
        cursor: pointer;
        color: blue;
        &:hover {
          opacity: 0.5;
        }
      }
      margin-bottom: 20px;
      table,
      tr,
      td {
        border: 3px double black;
      }
      table {
        width: 100%;
        .number {
          width: 10%;
        }
        td {
          padding: 5px;
          text-align: center;
        }
      }
      .noticeItem {
        margin-bottom: 20px;
        &Title {
          margin-left: 40px;
        }
        &Content {
          margin-left: 60px;
        }
      }
    }
  }
}
