.tabs {
  display: flex;
  .tab {
    width: fit-content;
    margin-right: 5px;
    &.current {
      color: white;
      background-color: #2684ff;
    }
  }
}
.plates {
  border: 1px solid black;
  margin-top: 20px;
  padding: 20px;
  hr {
    margin-bottom: 20px;
  }
  .btn {
    width: fit-content;
    display: flex;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  }
  .lomWrapper {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .lom {
      width: 100%;
      margin-bottom: 20px;
      font-size: 24px;
      text-align: center;
    }
  }
  .title {
    text-align: center;
    font-size: 32px;
    position: relative;
    max-width: fit-content;
    margin: 0 auto;
    margin-bottom: 20px;
    .editLom {
      position: absolute;
      right: -70px;
      top: -15px;
      font-size: 12px;
      padding: 2px;
    }
  }
  .formGroup {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
    .name {
      width: 25%;
    }
    .pdfFileName {
      cursor: pointer;
      width: 25%;
      color:blue;
    }
    label {
      width: 5%;
    }
    input {
      margin-right: 20px;
    }
    button {
      margin-right: 20px;
    }
    input[file] {
      padding: 0 10px;
      align-items: center;
      background-color: hsl(0deg, 0%, 100%);
      border-color: hsl(0deg, 0%, 80%);
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      cursor: default;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      min-height: 38px;
      outline: 0 !important;
      position: relative;
      transition: all 100ms;
      box-sizing: border-box;
    }
  }
}
.editorBtns{
  display: flex;
  margin-top: 20px;
  .btn{
    margin-right: 20px;
  }
}