.tableElement {
  min-width: 600px;
  .delete {
    display: block;
    margin: 5px auto;
cursor: pointer;
  }
  tr {
    vertical-align: middle;
    td {
      min-width: 50px;
      vertical-align: middle;
    }
    .tableHeaders {
      text-align: center;
      color: black;
    }
  }
}
.red{
  color: #e50058;
}