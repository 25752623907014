.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  .popup {
    width: fit-content;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #add8e6;
    padding: 20px 80px 30px;
    box-shadow: #add8e6aa 2px 3px 10px;
    font-size: 18px;
    .text {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .btn {
      width: fit-content;
    }
    .red {
      color: red;
    }
  }
}
