.table {
    width: 80vw;
    margin: 30px auto;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .tableItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 50px;
      margin: 20px;
      font-size: 32px;
      cursor: pointer;
      -moz-box-shadow: -1px 7px 11px 2px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: -1px 7px 11px 2px rgba(0, 0, 0, 0.5);
      -ms-box-shadow: -1px 7px 11px 2px rgba(0, 0, 0, 0.5);
      box-shadow: -1px 7px 11px 2px rgba(0, 0, 0, 0.5);
    }
  }