.tableField {
  margin: 5px;
  .tableElement {
    min-width: 600px;

    .delete {
      display: block;
      margin: 5px auto;
      cursor: pointer;
    }

    tr {
      vertical-align: middle;

      td {
        min-width: 50px;
        vertical-align: middle;
      }
      .trCount {
        width: 30px;
        min-width: 30px;
      }
      .tableHeaders {
        text-align: center;
        color: black;
      }
      .editSvg {
        text-align: center;
      }
      svg {
        cursor: pointer;
        color: #393939;
        font-size: 20px;

        &:hover {
          color: #2684ff;
        }
      }

      .deleteSvg {
        text-align: center;

        svg {
          color: #c72762e0;

          margin: 0;

          &:hover {
            color: #2684ff;
          }
        }
      }
    }
  }

  .red {
    color: red;
  }

  .pdf,
  .urlValue {
    cursor: pointer;
    text-decoration: underline;
  }

  .formGroup {
    color: #393939;
    display: flex;
    width: 200px;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    label {
      margin-right: 20px;
    }
  }
}

.fontRed {
  color: #e50058;
}