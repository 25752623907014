.btns {
  width: fit-content;
}
.formGroup {
  display: flex;
  width: 400px;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  &.formGroupBtn {
    width: 200px;
  }
  label {
    margin-right: 20px;
  }
}
.sectionName {
  font-size: 13.5pt;
  color: #0066d2;
  margin-bottom: 20px;
  display: flex;
  svg {
    cursor: pointer;
    margin: 0 0 0 8px;
    color: #393939;
    font-size: 20px;
    &:hover {
      color: #2684ff;
    }
  }
  .delete svg {
    color: #c72762e0;
    &:hover {
      color: #2684ff;
    }
  }
}
.section {
  margin-bottom: 20px;
  .addElement {
    width: fit-content;
    padding: 20px;
    border: 1px solid black;
  }
}
