.header {
  width: 700px;
  margin: 0 auto;
  padding-top: 20px;
  margin-bottom: 50px;
  .enter,
  .name {
    text-align: center;
  }
  .datePlace {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .date,
    .place {
      min-width: 310px;
      text-align: justify;
      font-size: 13.5pt;
      display: flex;
      justify-content: space-between;
    }
    .place {
      font-size: 12pt;
      span {
        font-size: 13.5pt;
      }
    }
  }
  .enter {
    font-size: 24pt;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 60px;
  }
  .name {
    color: blue;
    font-size: 36pt;
    margin-bottom: 40px;
  }
}
