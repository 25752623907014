body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  color: #393939;
  line-height: 1.5em;
}

/* HTML5 display-role reset for older browsers */

html::-webkit-scrollbar {
  width: 0;
}

a,
a:hover,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.bodyOverflow {
  overflow: hidden;
}
.btn1 {
  align-self: center;
  border: 1px solid #2684ff;
  cursor: pointer;
  padding: 5px 10px;
  transition: all 0.2s ease;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.4);
  &:hover {
    color: white;
    background-color: #2684ff;
  }
}
.btn {
  align-items: center;
  align-self: center;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-size: 16px;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.5rem - 1px) calc(1rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: fit-content;
  &:hover {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    color: white;
    background-color: #4394ff;
  }

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    background-color: #f0f0f1;
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
    color: rgba(0, 0, 0, 0.65);
    transform: translateY(0);
  }
}
.redBtn {
  background-color: #c72762e0;
  color: white;
}
.greenBtn {
  background-color: #62b965;
  color: white;
}

.input,
select {
  padding: 0 10px;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
  &:focus {
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
}
.input .bgGreen {
  background: green;
}
p {
  line-height: 1.2em;
}
.editBtn {
  appearance: auto;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  box-sizing: border-box;
  background-color: buttonface;
  border-width: 2px;
  border-style: outset;
  border-image: initial;
  padding: 2px 5px;
  margin-left: 10px;
  cursor: pointer;
  margin-top: 10px;
  color: black;
}
ins {
  color: #e50058;
  text-decoration: none;
}
del {
  display: none;
}
.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
.popup {
  width: fit-content;
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #add8e6;
  padding: 20px 40px 30px;
  box-shadow: #add8e6aa 2px 3px 10px;
  font-size: 18px;
  max-height: 90vh;
  overflow: auto;
  position: relative;
  .text {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .btn {
    width: fit-content;
  }
  .red {
    color: red;
  }
  .popupTitle {
    color: black;
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .popupFormGroup {
    display: flex;
    width: 400px;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    &.popupFormGroupBtn {
      width: 250px;
    }
    label {
      margin-right: 20px;
    }
  }
  .danger{
    color:red; font-size: 14px;
  }
}
@media print {
  .btn {
    display: none;
  }
}
